import { useRef } from "react";
import { useRouter } from "next/router";
import ArrowLeftIcon from "components/icons/arrow-left-icon";

type Header = {
  title?: string;
  callBacks: Function;
};

const Header = ({ title, callBacks }: Header) => {
  const router = useRouter();
  const backBtnRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <>
      <div className="relative">
        <div className={`fixed z-20 m-auto h-14 w-full max-w-xl bg-white`}>
          <a
            ref={backBtnRef}
            className="absolute z-40 h-14 w-14 cursor-pointer p-5 text-center"
            id={"backButton"}
            onClick={(e: any) => {
              callBacks(e);
            }}
          >
            <ArrowLeftIcon fill={"#1F2024"} />
          </a>

          <div className={`absolute left-1/2 w-full -translate-x-1/2 py-3.5 text-center text-xl font-bold`}>
            {title}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
