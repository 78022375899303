import { useEffect } from "react";
import { useRouter } from "next/router";
import { useAtom, useAtomValue } from "jotai";
import { ROUTE } from "@constants/route";
import {
  companyAtom,
  entryPesticideAtom,
  inflowTypeAtom,
  layoutVisible,
  loadingAtom,
  routeAtom,
  titleAtom,
} from "store/global";
import { ProgressBar } from "ui";
import Header from "./header";
import { ENTRY_TYPE, INFLOWTYPE } from "@constants/common";
import { checkVersion, QA_FLAG_STAGING } from "@vhows/util";

type Layout = {
  children?: React.ReactNode;
};

export default function Layout({ children }: Layout) {
  const [loading] = useAtom(loadingAtom);
  const [title, setTitle] = useAtom(titleAtom);
  const [entryPesticide, setEntryPesticide] = useAtom(entryPesticideAtom);
  const [inflowType, setInflowType] = useAtom(inflowTypeAtom);
  const [isVisible, setIsVisible] = useAtom(layoutVisible);
  const router = useRouter();
  const [company] = useAtom(companyAtom);

  const userId = router.query.uuid as string;
  const entryType = router.query.type as string;
  const address1 = router.query.address1 as string;
  const address2 = router.query.address2 as string;
  const address3 = router.query.address3 as string;

  useEffect(() => {
    if (!router.isReady) return;
    setIsVisible(true);
    enrtyCheck();
  }, [router.isReady]);

  useEffect(() => {
    if (checkVersion("2.1.0", QA_FLAG_STAGING)) {
      switch (router.pathname) {
        case ROUTE.DETAIL:
          if (entryType === ENTRY_TYPE.FACIL) {
            setTitle("시공사 프로필");
          } else if (
            entryType === ENTRY_TYPE.PESTICIDE ||
            entryType === ENTRY_TYPE.THE_PESTICIDE ||
            entryType === ENTRY_TYPE.NEAR_PESTICIDE
          ) {
            setTitle("농약사 프로필");
          }
          break;
        default:
          setTitle(
            entryType === ENTRY_TYPE.PESTICIDE
              ? "내 주변 농약사 찾기"
              : entryType === ENTRY_TYPE.THE_PESTICIDE
              ? address1 + " " + address2
              : entryType === ENTRY_TYPE.NEAR_PESTICIDE
              ? "내 주변 농약사"
              : "비닐하우스 시공사 찾기",
          );
          break;
      }
    } else {
      setTitle(
        entryType === ENTRY_TYPE.PESTICIDE
          ? "내 주변 농약사 찾기"
          : entryType === ENTRY_TYPE.THE_PESTICIDE
          ? address1 + " " + address2
          : entryType === ENTRY_TYPE.NEAR_PESTICIDE
          ? "내 주변 농약사"
          : "비닐하우스 시공사 찾기",
      );
    }
  }, [router.pathname, entryType]);

  useEffect(() => {
    if (checkVersion("2.1.0", QA_FLAG_STAGING) && router.pathname === ROUTE.MAP) {
      company.companyName && setTitle(`${company.companyName} 지도`);
    }
  }, [router.pathname, company]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const enrtyCheck = async () => {
    if (!!address1 && !!address2 && !!address3) {
      // 쿼리에 주소값이 전부 있을때 팜모닝을 경유한 접근임을 판단
      setInflowType("farmmorning");
    } else if (!entryPesticide) {
      setInflowType("geolocation");
    }
    setEntryPesticide(entryType === ENTRY_TYPE.PESTICIDE);
  };

  const callbacks = (e: any) => {
    switch (router.pathname) {
      // case ROUTE.ENTRYPOINT:
      // case ROUTE.LIST:
      // if (inflowType === INFLOWTYPE.FARMMORNING || entryPesticide) {
      //   location.href = "https://app.farmmorning.com/whole-menu/";
      // } else {
      //   location.href = "https://farmmorning-house.oopy.io/";
      // }
      // break;
      case ROUTE.LIST:
        if (inflowType === INFLOWTYPE.FARMMORNING || entryPesticide) {
          setIsVisible(false);
        }
      default:
        router.back();
        break;
    }
  };

  return (
    <>
      <div className={`relative m-auto max-w-xl flex-col`}>
        {!router.pathname.includes("images") || isVisible ? (
          <Header callBacks={callbacks} title={title}></Header>
        ) : null}
        {loading && <ProgressBar />}
        <div className={`relative bg-white min-h-screen ${!router.pathname.includes("images") ? "pt-14" : ""}`}>
          {children}
        </div>
      </div>
    </>
  );
}
