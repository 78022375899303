type Props = React.PropsWithChildren & {
  id?: string;
  dataGtm?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export function BottomFloatingButton({ id, dataGtm, onClick, children }: Props) {
  return (
    <div className="fixed bottom-0 w-full max-w-xl rounded-xl z-30">
      <div
        className="h-5 w-full"
        style={{ background: "linear-gradient( to top, rgba(255,255,255,1), rgba(255,255,255,0) )" }}
      />
      <div className="bg-white px-5 text-center">
        <button
          className="mb-5 h-14 w-full rounded-xl bg-[#12B564] text-white"
          id={id}
          data-gtm={dataGtm}
          onClick={onClick}
        >
          <div className="flex items-center justify-center">{children}</div>
        </button>
      </div>
    </div>
  );
}
