import { Companies } from "store/global";

export const CompanyData: Companies = {
  id: 0,
  companyName: "",
  district_1: "",
  district_2: "",
  district_3: "",
  address: {
    postcode: "",
    road: "",
    jibeon: "",
    detail: "",
    b_code: "",
    building_name: "",
    district3_h: "",
    district_1: "",
    district_2: "",
    district_3: "",
    h_code: "",
    latitude: 0,
    longitude: 0,
    main_address_no: "",
    main_building_no: "",
    mountain_yn: false,
    road_name: "",
    sub_address_no: "",
    sub_building_no: "",
    underground_yn: false,
  },
  badges: [],
  bizcallNumber: "",
  phoneNumber: "",
  phoneNumber2: "",
  latitude: 0,
  longitude: 0,
  distance: "",
  images: [],
  shippable: false,
  intro: "",
  handlingItems: [],
  homepageUrl: "",
  youtubeVideos: [],
};
