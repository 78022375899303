import { ReactElement } from "react";

type Props = { children: ReactElement; style?: React.CSSProperties };

export function IconContainer({ children, style }: Props) {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "0px",
        left: "-40px",
        width: "40px",
        height: "40px",
        background: "white",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "40px",
        ...style,
      }}
    >
      {children}
    </div>
  );
}
