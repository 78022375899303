export const INFLOWTYPE = {
  GEOLOCATION: "geolocation",
  FARMMORNING: "farmmorning",
  CUSTOM: "custom",
};

export const ENTRY_TYPE = {
  THE_PESTICIDE: "thepesticide",
  NEAR_PESTICIDE: "nearpesticide",
  PESTICIDE: "pesticide",
  FACIL: "facil",
};

export const PAGE_LIMIT = 12;

export const COMMON = {
  FARMMORNING_ACCESS_TOKEN: "fm-at",
  FARMMORNING_REFRESH_TOKEN: "fm-rt",
};

export const REGEXP = {
  ABSOLUTE_URL_PATTERN: /https?:\/\//g,
};
