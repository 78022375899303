const ArrowLeftIcon = ({ fill }: any) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.90717 0.71967C8.20006 0.426777 8.67494 0.426777 8.96783 0.71967C9.26072 1.01256 9.26072 1.48744 8.96783 1.78033L3.49816 7.25H16.3125C16.7267 7.25 17.0625 7.58579 17.0625 8C17.0625 8.41421 16.7267 8.75 16.3125 8.75H3.49816L8.96783 14.2197C9.26072 14.5126 9.26072 14.9874 8.96783 15.2803C8.67494 15.5732 8.20006 15.5732 7.90717 15.2803L1.15717 8.53033C0.864277 8.23744 0.864277 7.76256 1.15717 7.46967L7.90717 0.71967Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowLeftIcon;
