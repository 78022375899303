type ProgressBar = {
  /**
   * 로딩 텍스트 개행 문자 > '\n'
   */
  text?: string;
};

export const ProgressBar = ({ text = "로딩 중입니다.\n잠시만 기다려주세요." }: ProgressBar) => {
  const makeText = (text: string) => {
    return text.split("\n").map((textLine: string, index: number) => {
      return (
        <div key={index}>
          {textLine}
          <br />
        </div>
      );
    });
  };
  return (
    <>
      <div className="relative z-50">
        <div className="fixed inset-0 overflow-y-auto bg-gray-500 opacity-80"></div>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <div className="flex flex-col items-center justify-center rounded-lg bg-white p-8 text-center">
              <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-y-green-500 border-r-transparent border-l-green-500 text-green-500"></div>
              <span className="mt-2 text-sm font-medium text-gray-700">{makeText(text)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
