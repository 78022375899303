import Head from "next/head";
import { BottomFloatingButton } from "../bottom-floating-button-v1";
import { useRouter } from "next/router";

type ErrorProps = {
  statusCode?: number;
  padding?: boolean;
};

export const ErrorPage = ({ statusCode, padding }: ErrorProps) => {
  const router = useRouter();
  return (
    <>
      <Head>
        <title>결과 없음</title>
      </Head>
      <div className="relative m-auto max-w-xl flex-col">
        <div className={`mx-[1.25rem] flex flex-col ${padding ? "pt-14" : null}`}>
          <div className="mt-[1.25rem] mb-[0.563rem] text-[1.125rem] font-[700] leading-[1.375rem] tracking-[-0.02em] text-[#262626]">
            일시적으로 문제가 발생했습니다 😢
          </div>
          <div className="text-[#65666B]">이용에 불편을 드려 죄송합니다.</div>
          <div className="text-[#65666B]">다시 해보셔도 문제가 지속되면 고객 센터로 문의해주세요.</div>
        </div>
        <BottomFloatingButton id="retryButton" onClick={() => router.replace("/")}>
          <span className="ml-1 font-bold text-[19px]">홈으로 가기</span>
        </BottomFloatingButton>
      </div>
    </>
  );
};
