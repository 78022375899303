import "styles/globals.css";
import type { AppProps } from "next/app";
import { useAtomsDebugValue } from "jotai-devtools";
import Layout from "components/layout";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ErrorBoundary from "@vhows/util/error-boundary";
import { ErrorPage } from "ui/components/error";
import { useRouter } from "next/router";

export type PageProps = any;

function DebugAtoms() {
  useAtomsDebugValue();
  return null;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 100,
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <DebugAtoms />
        <Layout>
          <ErrorBoundary key={router.pathname} fallback={<ErrorPage />}>
            <Component {...pageProps} />
          </ErrorBoundary>
        </Layout>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
