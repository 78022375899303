import { atom } from "jotai";
import { region } from "@constants/region";
import { CompanyData } from "@constants/company";
import { cloneDeep } from "lodash-es";

export type selectAddress = {
  address1: string;
  address2: string;
  address3: string;
  latitude?: number;
  longitude?: number;
};

type selectRegion = {
  address1: string;
  address2: string;
  regionOption: region[];
};

export type mock = {
  companyName: string;
  adress1: string;
  address2: string;
  fullAddress: string;
  managerPhoneNumber: string;
  teleNumber: string;
};

type Address = {
  postcode: string;
  road: string;
  jibeon: string;
  detail: string;
  b_code: string;
  building_name: string;
  district3_h: string;
  district_1: string;
  district_2: string;
  district_3: string;
  h_code: string;
  latitude: number;
  longitude: number;
  main_address_no: string;
  main_building_no: string;
  mountain_yn: boolean;
  road_name: string;
  sub_address_no: string;
  sub_building_no: string;
  underground_yn: boolean;
};

type Images = {
  id: number;
  imageUrl: string;
  represent: boolean;
};

export type Badge = {
  id: number;
  badge: BadgeItem;
  badgeOrder: number;
};

type BadgeItem = {
  category: number;
  description: string;
  id: number;
  imageUrl: string;
  title: string;
};

export type HandlingItems = {
  category: string;
  name: string;
};

export type YoutubeVideos = {
  videoId: string;
  id: number;
  videoTitle: string;
  videoOrder: number;
  publicable: boolean;
};

export type Companies = {
  id: number;
  companyName: string;
  district_1: string;
  district_2: string;
  district_3: string;
  address: Address;
  badges: Badge[];
  phoneNumber: string;
  phoneNumber2: string;
  bizcallNumber: string;
  latitude: number;
  longitude: number;
  distance: string;
  images: Images[];
  shippable: boolean;
  intro: string;
  handlingItems: HandlingItems[];
  homepageUrl: string;
  youtubeVideos: YoutubeVideos[];
};

type InflowType = "geolocation" | "farmmorning" | "custom";

export type HandlingGroupItems = {
  category: string;
  items: HandlingItems[];
};

export const inflowTypeAtom = atom<InflowType>("farmmorning");
export const selectAddressDataAtom = atom<selectAddress>({
  address1: "",
  address2: "",
  address3: "",
  latitude: 0,
  longitude: 0,
});

export const mapLoadedAtom = atom<boolean>(false);
export const filterAddressAtom = atom<selectRegion>({
  address1: "",
  address2: "",
  regionOption: [],
});
export const currentLocationAtom = atom<GeolocationPosition | null>(null);
export const loadingAtom = atom<boolean>(false);
export const companiesAtom = atom<any>(null);
export const companyAtom = atom<Companies>(cloneDeep(CompanyData));
export const layoutVisible = atom<boolean>(false);
export const isScrollViewAtom = atom<boolean>(false);

/** 농약사 찾기 url 접속 판별 atom */
export const entryPesticideAtom = atom<boolean>(false);

/** 헤더 타이틀용 atom */
export const titleAtom = atom<string>("");

/** route 저장용 atom */
export const routeAtom = atom<string>("/");

/** handlingItemGroup atom */
export const groupingHandleItems = atom<HandlingGroupItems[] | null>(null);

/** 무한스크롤 최초 로드 여부 */
export const initListLoaded = atom<boolean>(false);

// default 주소
export const defaultAddress = {
  address1: "서울특별시",
  address2: "송파구",
  address3: "문정동",
  latitude: 37.4860499,
  longitude: 127.1168373,
};
